import React, { useEffect } from "react";
import $ from "jquery";
import SpreadsheetTable from "./Sheets";

interface AntsOptions {
  start: number;
  max: number;
}

declare global {
  interface Window {
    Ants: new (options: AntsOptions) => void;
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

const AntpireSPA: React.FC = () => {
  useEffect(() => {
    window.$ = window.jQuery = $;

    const script = document.createElement("script");
    script.src = "ants.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("ants.js loaded");
      if (window.Ants) {
        console.log("Ants constructor exists");
        try {
          new window.Ants({ start: 10, max: 25 });
          console.log("Ants initialized");
        } catch (error) {
          console.error("Error initializing Ants:", error);
        }
      } else {
        console.error("Ants constructor not found");
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ minHeight: "100vh", padding: "20px" }}>
      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto 20px auto",
          textAlign: "center",
        }}
      >
        <img
          src="/antpire-banner.png"
          alt="The Antpire"
          style={{ width: "100%", height: "auto", marginBottom: "20px" }}
        />
      </div>
      <SpreadsheetTable />
    </div>
  );
};

export default AntpireSPA;
